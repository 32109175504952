import { graphql } from 'gatsby'
import React from 'react'
import ContentPage from '~/components/Content/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Metadata title={data.home.name} />
      <ContentPage page={data.home} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage($locale: String) {
    home: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      ...ContentHeroFragment
      ...ContentPageFragment
    }
  }
`
